<app-spinner *ngIf="showSpinner"></app-spinner>
<div class="mainContainer vw-100" [style.height.px]="screenHeight">
    <app-nav *ngIf="showNav && isDesktop"></app-nav>
    <div *ngIf="showNav && isMobile" class="d-flex flex-column mobileAlignment">
        <div class="topBar d-flex justify-content-end">
            <span class="pi pi-shopping-cart"></span>
        </div>
        <div class="mobileBody d-flex" [style.height]="'calc(' + screenHeight + 'px - 5.8rem)'">
            <div class="welcomeDiv d-flex flex-column gap-2 w-100">
                <span class="userBanner text-muted">
                    Hi, {{userData.firstName}} <span class="wave-emoji">👋</span>
                </span>
                <span class="userQuestBanner">
                    What would you <br> like to order today ?
                </span>
                <span class="searchDiv">
                    <input class="w-100" type="text" pInputText [(ngModel)]="searchValue" placeholder="Search for Restaurant or Food" />
                </span>
                <span class="emptyResMsg d-flex flex-column justify-content-center align-items-center">
                    <img src="../../../assets/illustrations/noActiveRestaurants.png" alt="">
                    <span class="mt-2">
                        <h5>No active outlets</h5>
                    </span>
                </span>
            </div>
            <div class="bottomNav">
                <div class="bottomNavContent">
                    <div class="bottomNavActiveBackground"
                        [ngStyle]="{'left': activeLeft + 'px', 'top': activeTop + 'px'}"></div>
                    <span [ngClass]="activeScreen === 'home' ? 'bottomNavItemActive' : 'bottomNavItem'"
                        (click)="updateActiveScreen('home', $event)">
                        <span class="pi pi-home"></span>
                    </span>
                    <span [ngClass]="activeScreen === 'orderHistory' ? 'bottomNavItemActive' : 'bottomNavItem'"
                        (click)="updateActiveScreen('orderHistory', $event)">
                        <span class="pi pi-history"></span>
                    </span>
                    <span [ngClass]="activeScreen === 'profile' ? 'bottomNavItemActive' : 'bottomNavItem'"
                        (click)="updateActiveScreen('profile', $event)">
                        <span class="pi pi-user"></span>
                    </span>
                    <span [ngClass]="activeScreen === 'settings' ? 'bottomNavItemActive' : 'bottomNavItem'"
                        (click)="updateActiveScreen('settings', $event)">
                        <span class="pi pi-cog"></span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
<p-dialog header="Sign Up" class="signUpModal" [modal]="true" [(visible)]="showCompleteRegModal" [draggable]="false"
    [closable]="false">
    <div class="mt-4">
        <span class="p-text-secondary">We are really happy to have you on board. Please fill in the following details to
            complete the sign up process.</span>
    </div>
    <form [formGroup]="userRegistrationForm">
        <div class="d-flex flex-column gap-3 my-4">
            <div class="d-flex flex-column gap-2">
                <label for="firstName">First Name</label>
                <input pInputText id="firstName" formControlName="firstName" />
            </div>
            <div class="d-flex flex-column gap-2">
                <label for="lastName">Last Name</label>
                <input pInputText id="lastName" formControlName="lastName" />
            </div>
            <div class="d-flex flex-column gap-2 mb-2">
                <label for="mobileNumber">Mobile Number</label>
                <input pInputText id="mobileNumber" formControlName="mobileNumber" />
            </div>
        </div>
    </form>
    <div class="d-flex justify-content-end gap-2">
        <p-button label="Save" [disabled]="userRegistrationForm.invalid" (onClick)="updateUserDetails()" />
    </div>
</p-dialog>