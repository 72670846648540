import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as lottie from 'lottie-web';

@Component({
  selector: 'app-outlet-show-case',
  templateUrl: './outlet-show-case.component.html',
  styleUrl: './outlet-show-case.component.scss'
})
export class OutletShowCaseComponent {

  searchValue: string | null = null;
  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  restaurantsArr: any[] = [];
  @Input() userData: any;
  showCartIconContainer: boolean = false;
  @ViewChild('scheduleIconContainer') scheduleIconContainer!: ElementRef;
  @ViewChild('heartIconContainer') heartIconContainer!: ElementRef;
  @ViewChild('cartIconContainer') cartIconContainer!: ElementRef;
  @ViewChild('starRatingAnimIcon') starRatingIconContainer!: ElementRef;
  @ViewChild('rightArrowIconContainer') rightArrowIconContainer!: ElementRef;

  constructor(private router: Router, private cdr: ChangeDetectorRef) {
    this.getScreenSize();
    this.restaurantsArr = [
      {
        "restaurantId": "123456",
        "locality": "Sanivarapupeta",
        "restaurantLocation": {
          "restaurantLong": 81.0883096,
          "restaurantLat": 16.7190833
        },
        "restaurantTimings": {
          "wednesday": {
            "startTime": 1719118800591,
            "endTime": 1719163800389
          },
          "monday": {
            "startTime": 1719118800591,
            "endTime": 1719163800389
          },
          "thursday": {
            "endTime": 1719163800389,
            "startTime": 1719118800591
          },
          "sunday": {
            "startTime": 1719118800591,
            "endTime": 1719163800389
          },
          "saturday": {
            "endTime": 1719163800389,
            "startTime": 1719118800591
          },
          "friday": {
            "endTime": 1719163800389,
            "startTime": 1719118800591
          },
          "tuesday": {
            "startTime": 1719118800591,
            "endTime": 1719163800389
          }
        },
        "restaurantCuisines": [
          "Burger",
          "Sandwich",
          "Pasta"
        ],
        "isOnline": true,
        "restaurantName": "Fruitify"
      },
    ]
  }

  ngAfterViewInit() {
    this.showCartIconContainer = true;
    var timer = setInterval(() => {
      if (this.scheduleIconContainer?.nativeElement) {
        clearInterval(timer);
        const scheduleIconUrl = '../../../assets/animIcons/scheduleIcon.json';
        const heartIconUrl = '../../../assets/animIcons/heartIcon.json';
        const shoppingBagUrl = '../../../assets/animIcons/shoppingBag.json';
        const starIconUrl = '../../../assets/animIcons/starIcon.json';
        const rightArrowUrl = '../../../assets/animIcons/rightArrow.json';
        let scheduleAnimInstance: any;
        let heartAnimInstance: any;
        let shoppingAnimInstance: any;
        let starIconAnimInstance: any;
        let rightArrowAnimInstance: any;
        fetch(scheduleIconUrl)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(animationData => {
            scheduleAnimInstance = (lottie as any).loadAnimation({
              container: this.scheduleIconContainer.nativeElement,
              renderer: 'svg',
              loop: false,
              autoplay: true,
              animationData: animationData
            });
            scheduleAnimInstance.setSpeed(0.8)
            let playCount = 0;
            scheduleAnimInstance.addEventListener('complete', () => {
              playCount++;
              if (playCount < 2) {
                scheduleAnimInstance.goToAndPlay(0, true); // Play from the beginning again
              }
            });
          })
          .catch(error => {
            console.error('Error loading animation:', error);
          });

        setTimeout(() => {
          const svgElement = this.scheduleIconContainer.nativeElement.querySelector('svg');
          if (svgElement) {
            const paths = svgElement.querySelectorAll('path'); // Select all path elements within the SVG

            paths.forEach((path: any) => {
              path.style.stroke = 'black'; // Set the stroke color
            });
          }
        }, 100);
        
        fetch(heartIconUrl)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(animationData => {
            heartAnimInstance = (lottie as any).loadAnimation({
              container: this.heartIconContainer.nativeElement,
              renderer: 'svg',
              loop: false,
              autoplay: true,
              animationData: animationData
            });
            heartAnimInstance.setSpeed(0.4)
            var originalTimer = setInterval(() => {
              const svgElement = this.heartIconContainer.nativeElement.querySelector('svg');
              let originalFill = null; 
              if (svgElement) {
                clearInterval(originalTimer)
                const paths = svgElement.querySelectorAll('path');
                originalFill = paths[0].style.fill; // Assuming the first path has the fill
  
                // Set fill color to red for animation
                paths.forEach((path: any) => {
                  path.style.stroke = '#046C95';
                  path.style.fill = '#046C95';
                });
              }
            },);
            let playCount = 0;
            heartAnimInstance.addEventListener('complete', () => {
              playCount++;
              if (playCount < 2) {
                heartAnimInstance.goToAndPlay(0, true); // Play from the beginning again
              }
            });
          })
          .catch(error => {
            console.error('Error loading animation:', error);
          });

        setTimeout(() => {
          const svgElement = this.heartIconContainer.nativeElement.querySelector('svg');
          if (svgElement) {
            const paths = svgElement.querySelectorAll('path'); // Select all path elements within the SVG

            paths.forEach((path: any) => {
              path.style.stroke = '#046C95'; // Set the stroke color
            });
          }
        }, 100);

        fetch(starIconUrl)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(animationData => {
            starIconAnimInstance = (lottie as any).loadAnimation({
              container: this.starRatingIconContainer.nativeElement,
              renderer: 'svg',
              loop: false,
              autoplay: true,
              animationData: animationData
            });
            starIconAnimInstance.setSpeed(0.8)
          })
          .catch(error => {
            console.error('Error loading animation:', error);
          });

          var starTimer = setInterval(() => {
            const svgElement = this.starRatingIconContainer.nativeElement.querySelector('svg');
            if (svgElement) {
              clearInterval(starTimer)
              const paths = svgElement.querySelectorAll('path'); // Select all path elements within the SVG
  
              paths.forEach((path: any) => {
                path.style.stroke = 'white';
                path.style.fill = 'white';
              });
            }
          }, );

          fetch(rightArrowUrl)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(animationData => {
            rightArrowAnimInstance = (lottie as any).loadAnimation({
              container: this.rightArrowIconContainer.nativeElement,
              renderer: 'svg',
              loop: false,
              autoplay: true,
              animationData: animationData
            });
            rightArrowAnimInstance.setSpeed(0.6)
            let playCount = 0;
            rightArrowAnimInstance.addEventListener('complete', () => {
              playCount++;
              if (playCount < 2) {
                rightArrowAnimInstance.goToAndPlay(0, true); // Play from the beginning again
              }
            });
          })
          .catch(error => {
            console.error('Error loading animation:', error);
          });

          var arrowTimer = setInterval(() => {
            const svgElement = this.rightArrowIconContainer.nativeElement.querySelector('svg');
            if (svgElement) {
              clearInterval(arrowTimer)
              const paths = svgElement.querySelectorAll('path'); // Select all path elements within the SVG

              paths.forEach((path: any) => {
                path.style.stroke = 'white';
                path.style.strokeWidth = '50';
              });
            }
          }, );

      }

    },);
    this.cdr.detectChanges()
  }

  openCart() { }

  formattedFoodItems(cuisinesArr: string[]): string {
    return cuisinesArr.join(' • ');
  }

  openRestaurant(resId: string) {
    localStorage.setItem('selectedRes', resId);
    this.router.navigateByUrl('outletMenu')
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }
}
