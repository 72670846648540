import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '../../shared/toast.service';
import { CookieService } from 'ngx-cookie-service'; // Assuming you're using ngx-cookie-service
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  /*isSignin: boolean = true;
  mobile(){
   this.isSignin =!this.isSignin;
  }   
  email(){
   this.isSignin =!this.isSignin;
  }
  isMainPage: boolean =true;
  logIn(){
     this.isMainPage=!this.isSignin;
  }
  otp:boolean =true;
  sendOtp(){
   this.otp = !this.otp
  }*/
  isSignin: boolean = true;
  showSpinner = false;
  userRegistrationForm: FormGroup;
  userLoginForm: FormGroup;

  constructor(private fb: FormBuilder, private firebaseAuth: AngularFireAuth, private toastService: ToastService,
  private cookieService: CookieService, private userService: UserService, private router: Router  ) {
    this.userRegistrationForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });
    this.userLoginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  signIn() {
    this.isSignin = !this.isSignin
  }

  signUp() {
    this.isSignin = !this.isSignin
  }

  initUserRegistration() {
    this.showSpinner = true;
    if (this.userRegistrationForm.valid) {
      const email = this.userRegistrationForm.value.email;
      const password = this.userRegistrationForm.value.password;
      const confirmPassword = this.userRegistrationForm.value.confirmPassword;
  
      if (password !== confirmPassword) {
        // Handle password mismatch error (e.g., show an error message)
        console.error('Passwords do not match');
        return; 
      }
  
      // Firebase Authentication user creation
      this.firebaseAuth.createUserWithEmailAndPassword(email, password)
        .then((userCredential) => {
          // User creation successful
          const user: any = userCredential.user;
          console.log('User created:', user);
          var obj = {
            uid: user.uid,
            email: email,
            createdAt: new Date().valueOf()
          }
          this.userService.registerNewUser(user.uid, obj);
          this.showSpinner = false;
          this.toastService.showSuccess("User account creation successful");
          this.router.navigateByUrl('/home');
          // const expiryDate = new Date();
          // expiryDate.setMinutes(expiryDate.getMinutes() + 1); 
          // this.cookieService.set('userLoggedIn', 'true', expiryDate);
          // this.cookieService.set('sessionId', this.generateSessionId(), expiryDate);
          // Optionally, perform additional actions (e.g., navigate to a dashboard)
        })
        .catch((error) => {
          // Handle errors (e.g., display an error message)
          this.showSpinner = false;
          console.error('Error creating user:', error);
        });
      } else {
        // Handle invalid form submission (e.g., mark fields as touched to show errors)
        this.showSpinner = false;
      this.userRegistrationForm.markAllAsTouched();
    }
  }

  userLogin() {
    this.showSpinner = true;
    if (this.userLoginForm.valid) { // Assuming you have a 'userLoginForm' FormGroup
      const email = this.userLoginForm.value.email;
      const password = this.userLoginForm.value.password;
  
      // Firebase Authentication user login
      this.firebaseAuth.signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          // User login successful
          const user = userCredential.user;
          console.log('User logged in:', user);
          this.showSpinner = false;
          this.router.navigateByUrl('/home');
          // Optionally, perform additional actions (e.g., navigate to a dashboard, set cookies, etc.)
        })
        .catch((error) => {
          this.showSpinner = false;
          // Handle errors (e.g., display an error message)
          console.error('Error logging in user:', error);
        });
      } else {
      this.showSpinner = false;
      // Handle invalid form submission (e.g., mark fields as touched to show errors)
      this.userLoginForm.markAllAsTouched();
    }
  }

  generateSessionId(): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 12; i++)   
   {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;   
  
  }
}
