import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../../environments/environment';
import { HomeComponent } from './components/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { LoginComponent } from './components/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { GoogleMapsModule } from '@angular/google-maps';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { UserSettingsComponent } from './components/settings/user-settings/user-settings.component';
import { PrivacySecurityComponent } from './components/settings/privacy-security/privacy-security.component';
import { NavComponent } from './components/nav/nav.component';
import { NotificationSettingsComponent } from './components/settings/notification-settings/notification-settings.component';
import { HttpClientModule } from '@angular/common/http';
import { OutletMenuComponent } from './components/outlet-menu/outlet-menu.component';
import { ToolbarModule } from 'primeng/toolbar';
import { InputGroupModule } from 'primeng/inputgroup';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { CardModule } from 'primeng/card';
import { ChipModule } from 'primeng/chip';
import { AccordionModule } from 'primeng/accordion';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { InputOtpModule } from 'primeng/inputotp';
import { OutletShowCaseComponent } from './components/outlet-show-case/outlet-show-case.component';
import { UserSettingsLandingComponent } from './components/user-settings-landing/user-settings-landing.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    NotFoundComponent,
    SpinnerComponent,
    UserSettingsComponent,
    PrivacySecurityComponent,
    NavComponent,
    NotificationSettingsComponent,
    OutletMenuComponent,
    SignUpComponent,
    OutletShowCaseComponent,
    UserSettingsLandingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    GoogleMapsModule,
    BrowserAnimationsModule,
    ToastModule,
    DialogModule,
    ProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule,
    OverlayPanelModule,
    HttpClientModule,
    ToolbarModule,
    InputGroupModule,
    IconFieldModule,
    InputIconModule,
    CardModule,
    ChipModule,
    AccordionModule,
    InputOtpModule
  ],
  providers: [MessageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
