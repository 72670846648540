<p-toast></p-toast> 
<p-dialog [(visible)]="showSplash" [modal]="true" [style]="{width: '100vw', height: '100vh'}" [baseZIndex]="10000" [closable]="false">
    <ng-template pTemplate="headless">
        <div class="splashDiv d-flex h-100 w-100 justify-content-center align-items-center">
            <div class="d-flex align-items-baseline" style="gap: 3px;">
                <span class="aahaarTitleOblong">AA</span>
                <span class="aahaarTitle">haar</span>
            </div>
        </div>
    </ng-template>
</p-dialog>

<router-outlet class="routerDiv" *ngIf="!showSplash" [style.height.px]="screenHeight"></router-outlet>
