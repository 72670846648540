import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrl: './user-settings.component.scss'
})
export class UserSettingsComponent {

  showSpinner: boolean = false;
  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  activeAction: string | null = null

  constructor(private router: Router) {
    this.getScreenSize();
    if(window.location.href.indexOf('notification') != -1) {
      this.activeAction = 'notification';
    } else {
      this.activeAction = 'privacy';
    }
  }

  navigateTo(action: string) {
    this.activeAction = action;
    this.router.navigateByUrl(action == 'notification' ? 'user-settings/notification' : 'user-settings/privacy-security')
  }

  calHeight(): string {
    return `calc(${this.screenHeight} - 58px - 1.5rem);`;
  }

  updateSpinnerBool(event: any) {
    console.warn(event);
    
    this.showSpinner = event;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }
}
