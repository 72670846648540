<!--<div class="mainContainer" *ngIf="isMainPage" >
    <div class="mainHeader">
        <span class="subHeader">AA</span>haar
    </div>
    <div class="formBtn">
    <div class="loginBtn d-flex justify-content-center" >
        <button class="btn btn-light btn-block  w-50"  (click)="logIn()">Login</button>
    </div>
    <br>
    <div class="signInBtn d-flex justify-content-center">
        <button class="btn btn-success btn-block  w-50 ">Sign Up</button>
    </div>
    </div>
</div>
<div class="subContainer">
    <div class="mainHead">
        <span class="subHeader">AA</span>haar
    </div>
<div *ngIf="isSignin">
    <div class="formFeild">
        <div class="emailInput">
            <label for="email">User Name/Email ID</label><br>
            <input class="inputFeild" type="email" id="email"><i class="pi pi-at"></i>
        </div>
        <div class="passwordInput">
            <label for="pwd" >Password</label><br>
            <input class="inputFeild" type="password" id="pwd"><i class="pi pi-lock"></i>
        </div>
        <div class="recogniser">
            <div class="remember">
            <input type="checkbox" id="remember" class="form-check-input">
             <label class="checkInput" for="remember"> Remember Me</label>
            </div>
            <div class="forget">
                Forgot Password?
            </div>
        </div>
        <br>
        <div class="loginBtn d-flex justify-content-center">
            <button class="Btn w-75">Login</button>
        </div>
        <br>
        <div class="seperator">
            (Or)
        </div>
        <div class="socialBtn w-100">
        <div class="googleBtn d-flex justify-content-center">
            <button class="Btn w-75"><i class="pi pi-google"></i> Sign In with Google</button>
        </div>
        <div class="mobileBtn d-flex justify-content-center">
            <button class="Btn w-75" (click)="mobile()"><i class="pi pi-mobile"></i> Sign In with Mobile</button>
        </div>
        </div>
        <div class="signupMessage">
            Don't have an account?<span class="signup">Sign Up</span>
        </div>
    </div>
</div>
<div  *ngIf="!isSignin">
    <div class="mainHead">
        <span class="subHeader">AA</span>haar
    </div>
    <div class="formFeild">
        <div class="contactInfo">
            <label for="number">Mobile Number</label><br>
            <input class="inputFeild" type="text" id="number"><i class="pi pi-mobile"></i>
        </div>
        <br>
        <div class="sendBtn d-flex justify-content-center">
            <button class="Btn w-75">Send OTP</button>
        </div>
        <br>
        <div class="seperator">
            (Or)
        </div>
        <div class="socialBtn w-100">
        <div class="googleBtn d-flex justify-content-center">
            <button class="Btn w-75">Sign In with Google</button>
        </div>
        <div class="mailBtn d-flex justify-content-center">
            <button class="Btn w-75" (click)="email()"><i class="pi pi-envelope"></i> Sign In with Email</button>
        </div>
        </div>
        <div class="signupCall">
            Don't have an account?<span class="signup">Sign Up</span>
        </div>
    </div>
</div>
</div>
<div class="subContainer">
    <div class="mainHead">
        <span class="subHeader">AA</span>haar
    </div>
    <div class="formFeild">
        <div class="formInfo">
            <span class="subInfo">
                Verification Required
            </span>
            <br>
            <span class="otpText">
                Enter your 6-Digits OTP which is sent to your mobile number here...
            </span>
        </div>
        <div class="otpContainer">
                <input class="input" type="text"  maxlength="1" >
                <input class="input" type="text"  maxlength="1" >
                <input class="input" type="text"  maxlength="1" >
                <input class="input" type="text"  maxlength="1" >
                <input class="input" type="text"  maxlength="1" >
                <input class="input" type="text"  maxlength="1" >
        </div>
        <br>
        <div class="confirmBtn d-flex justify-content-center">
            <button class="Btn w-75">Confirm</button>
        </div>
        <br>
        <div class="seperator">
            (Or)
        </div>
        <div class="socialBtn w-100">
        <div class="googleBtn d-flex justify-content-center">
            <button class="Btn w-75"><img src="../../assets/GoogleIcons.png" alt="" >Sign In with Google</button>
        </div>
        <div class="emailBtn d-flex justify-content-center">
            <button class="Btn w-75" ><i class="pi pi-envelope"></i> Sign In with Email</button>
        </div>
        </div>
    </div>
</div>-->
<app-spinner *ngIf="showSpinner"></app-spinner>
<div class="mainContainer">
    <div class="loginContainer" *ngIf="isSignin">
        <div class="mainHeader">
            AA<span class="headerPart">haar</span>
        </div>
        <br>
        <div class="header">
            Welcome Back,
        </div>
        <div class="welcomeText">
            Please enter Your details
        </div>
        <div class="formGroup">
            <button class="toggleButton active">Sign In</button>
            <button class="toggleButton" (click)="signIn()">Sign Up</button>
        </div>
        <form [formGroup]="userLoginForm">
            <div class="formField">
                <div class="inputContainer">
                    <i class="pi pi-mobile"></i>
                    <hr class="verticalLine">
                    <div class="flex-1 w-75">
                        <label for="email">Email</label>
                        <br>
                        <input type="text" id="email" placeholder="example@gmail.com" formControlName="email">
                    </div>
                </div>
                <div class="inputContainer">
                    <i class="pi pi-lock"></i>
                    <hr class="verticalLine">
                    <div class="flex-1 w-75">
                        <label for="password">Password</label>
                        <br>
                        <input type="password" id="password" placeholder="********" formControlName="password">
                    </div>
                </div>
                <button class="btn w-50" [disabled]="userLoginForm.invalid" (click)="userLogin()">Sign In</button>
                <div class="alternativeLogin">
                    <p>Or Continue With</p>
                    <div class="socialButtons">
                        <button class="socialBtn google"></button>
                        <button class="socialBtn mobile"></button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="loginContainer" *ngIf="!isSignin">
        <div class="mainHeader">
            AA<span class="headerPart">haar</span>
        </div>
        <br>
        <div class="header">
            Welcome,
        </div>
        <div class="welcomeText">
            Please enter Your details
        </div>
        <div class="formGroup">
            <button class="toggleButton " (click)="signUp()">Sign In</button>
            <button class="toggleButton active">Sign Up</button>
        </div>
        <form [formGroup]="userRegistrationForm">
            <div class="formField">
                <div class="inputContainer">
                    <i class="pi pi-envelope"></i>
                    <hr class="verticalLine">
                    <div class="flex-1 w-75">
                        <label for="email">Email Address</label>
                        <br>
                        <input type="email" id="email" value="example@gmail.com" formControlName="email">
                    </div>
                </div>
                <div class="inputContainer">
                    <i class="pi pi-lock"></i>
                    <hr class="verticalLine">
                    <div class="flex-1 w-75">
                        <label for="password">Password</label>
                        <br>
                        <input type="password" id="password" value="************" formControlName="password">
                    </div>
                </div>
                <div class="inputContainer">
                    <i class="pi pi-lock"></i>
                    <hr class="verticalLine">
                    <div class="flex-1 w-75">
                        <label for="password">Confirm Password</label>
                        <br>
                        <input type="password" id="password" value="************" formControlName="confirmPassword">
                    </div>
                </div>
                <button class="btn w-50" (click)="initUserRegistration()">Sign up</button>
            </div>
        </form>
    </div>
</div>