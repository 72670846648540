import { Component , CUSTOM_ELEMENTS_SCHEMA, HostListener} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-outlet-menu',
  templateUrl: './outlet-menu.component.html',
  styleUrl: './outlet-menu.component.scss'
})

export class OutletMenuComponent {

  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  accordionItems = [
    { title: 'Text Item', type: 'text', description: 'This is some text content.', selected: true },
    { title: 'Image Item', type: 'image', imageUrl: 'https://via.placeholder.com/150', selected: false },
    { title: 'Another Text Item', type: 'text', description: 'Another text item content.', selected: false }
  ];

  constructor(private router: Router) {
    this.getScreenSize()
  }

  navigateToHome() {
    this.router.navigateByUrl('home')
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }

}
