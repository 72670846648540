import { Component, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-rain-effect',
  templateUrl: './rain-effect.component.html',
  styleUrl: './rain-effect.component.scss'
})
export class RainEffectComponent {

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
    this.makeItRain();
  }

  makeItRain(): void {
    const rainFrontRow = document.querySelector('.rain.front-row');
    const rainBackRow = document.querySelector('.rain.back-row');

    if (rainFrontRow && rainBackRow) {
      rainFrontRow.innerHTML = '';
      rainBackRow.innerHTML = '';

      let increment = 0;
      let drops = '';
      let backDrops = '';

      while (increment < 100) {
        const randoHundo = Math.floor(Math.random() * (98 - 1 + 1) + 1);
        const randoFiver = Math.floor(Math.random() * (5 - 2 + 1) + 2);

        increment += randoFiver;

        const dropHTML = `
          <div class="drop" style="left: ${increment}%; bottom: ${randoFiver + randoFiver - 1 + 100}%; animation-delay: 0.${randoHundo}s; animation-duration: 0.5${randoHundo}s;">
            <div class="stem" style="animation-delay: 0.${randoHundo}s; animation-duration: 0.5${randoHundo}s;"></div>
            <div class="splat" style="animation-delay: 0.${randoHundo}s; animation-duration: 0.5${randoHundo}s;"></div>
          </div>`;

        drops += dropHTML;
        backDrops += dropHTML.replace('left', 'right');
      }

      rainFrontRow.innerHTML = drops;
      rainBackRow.innerHTML = backDrops;
    }
  }

  ngAfterViewInit(): void {
    this.renderer.listen('.splat-toggle', 'click', () => {
      document.body.classList.toggle('splat-toggle');
      this.makeItRain();
    });

    this.renderer.listen('.back-row-toggle', 'click', () => {
      document.body.classList.toggle('back-row-toggle');
      this.makeItRain();
    });

    this.renderer.listen('.single-toggle', 'click', () => {
      document.body.classList.toggle('single-toggle');
      this.makeItRain();
    });
  }
  
}
