// connection.service.ts
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ConnectionService implements OnDestroy {
    private isOnline$ = new BehaviorSubject<boolean>(navigator.onLine);

    private onlineSubscription!: Subscription;
    private offlineSubscription!: Subscription;

    constructor() {
        this.onlineSubscription = fromEvent(window, 'online').subscribe(() => {
            this.updateOnlineStatus(true);
        });

        this.offlineSubscription = fromEvent(window, 'offline').subscribe(() => {
            this.updateOnlineStatus(false);
        });
    }

    get isOnline() {
        return this.isOnline$.asObservable();
    }

    private updateOnlineStatus(status: boolean) {
        this.isOnline$.next(status);
    }

    ngOnDestroy() {
        this.onlineSubscription.unsubscribe();
        this.offlineSubscription.unsubscribe();
    }
}
