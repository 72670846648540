import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AuthGuard } from './shared/auth.guard';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { LoginComponent } from './components/login/login.component';
import { UserSettingsComponent } from './components/settings/user-settings/user-settings.component';
import { PrivacySecurityComponent } from './components/settings/privacy-security/privacy-security.component';
import { NotificationSettingsComponent } from './components/settings/notification-settings/notification-settings.component';
const routes: Routes = [
  // {path:'', redirectTo:'/home', pathMatch:'full'},
  {path:'login', component: LoginComponent},
  {
    path:'home',
    component: HomeComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'user-settings',
    component: UserSettingsComponent,
    children: [
      {
        path: '',
        redirectTo: 'notification',
        pathMatch: 'full'
      },
      {
        path: 'notification',
        component: NotificationSettingsComponent
      },
      {
        path: 'privacy-security',
        component: PrivacySecurityComponent
      }
    ]
  },
  {
    path: '**',
    component: NotFoundComponent
    
  }  
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
