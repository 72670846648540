import { Component, HostListener } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { UserService } from '../../services/user.service';
import { ToastService } from '../../shared/toast.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrl: './nav.component.scss'
})
export class NavComponent {

  user: any;
  userData: any;
  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;

  constructor(private afAuth: AngularFireAuth, private userService: UserService, private toastService: ToastService, private router: Router) {
    this.getScreenSize();
    firebase.auth().onAuthStateChanged((user: any) => {
      if (user) {
        this.user = user;
        this.userService.getCurrentUser(this.user.uid).subscribe((res: any) => {
          if (!res.data().hasOwnProperty('firstName')) {
            console.warn('complete sign up');
          } else {
            this.userData = res.data();
          }
        })
      } else {
        console.warn("User not logged in");
        // User not logged in or has just logged out.
      }
    });
  }

  logOut() {
    this.afAuth.signOut()
      .then(() => {
        this.toastService.showSuccess("User logged out successfully")
        this.router.navigateByUrl('/login')
        console.log('User logged out successfully');
        // Perform any additional actions after logout, like:
        // - Clear user data from local storage or cookies
        // - Navigate to the login page
      })
      .catch((error) => {
        console.error('Error logging out:', error);
        // Handle any logout errors
      });
  }
  
  navigateToUserSettings() {
    this.router.navigateByUrl('user-settings');
  }
  
  navigateToHome() {
    this.router.navigateByUrl('home');
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }
}
