<app-spinner *ngIf="showSpinner"></app-spinner>
<app-nav></app-nav>
<div class="mainContainer mt-4 d-flex">
    <div class="sideBar d-flex flex-column" [style.height]="'calc(' + screenHeight + 'px - 58px - 3rem)'">
        <div class="sideBarItem d-flex gap-2 align-items-center" (click)="navigateTo('notification')">
            <span class="pi pi-bell"></span>
            <span>Notifications</span>
        </div>
        <div class="sideBarItem d-flex gap-2 align-items-center" (click)="navigateTo('privacy')">
            <span class="pi pi-shield"></span>
            <span>Privacy and Security</span>
        </div>
    </div>
    <div class="routerContent" [style.height]="'calc(' + screenHeight + 'px - 58px - 3rem)'">
        <app-notification-settings *ngIf="activeAction == 'notification'"></app-notification-settings>
        <app-privacy-security  *ngIf="activeAction == 'privacy'" (showSpinner)="updateSpinnerBool($event)"></app-privacy-security>
    </div>
</div>