import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  registeredUsersCollection: AngularFirestoreCollection;
  restaurantCollection: AngularFirestoreCollection;

  constructor(private fireStore: AngularFirestore, private http: HttpClient) { 
    this.registeredUsersCollection = fireStore.collection('users');
    this.restaurantCollection = fireStore.collection('registeredRestaurants');
  }

  registerNewUser(uid: string, obj: any) {
    return this.registeredUsersCollection.doc(uid).set(obj);
  }

  getCurrentUser(uid: string) {
    return this.registeredUsersCollection.doc(uid).get();
  }

  completeUserRegistration(uid: string, obj: any) {
    const data = {
      firstName: obj.firstName,
      lastName: obj.lastName,
      mobileNumber: obj.mobileNumber,
    }
    return this.registeredUsersCollection.doc(uid).set(data, {merge: true});
  }

  deleteCurrentUser(uid: string) {
    return this.registeredUsersCollection.doc(uid).delete();
  }

  getWeatherData(latitude: number, longitude: number) {
    const apiKey = 'b9940d46192146b887a105454242208';
    const apiUrl = `http://api.weatherapi.com/v1/current.json?key=${apiKey}&q=${latitude},${longitude}`;
  
    return this.http.get(apiUrl);
  }

  getRestaurantsList() {
    return this.restaurantCollection.get()
  }

  checkMobile(mobile: string) {
    return this.fireStore.collection('users', ref => 
      ref.where('mobileNumber', '==', mobile)
    ).get(); 
  }
}
