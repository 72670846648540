<p-button label="Delete Account" severity="danger" icon="pi pi-trash" (onClick)="enableDeleteModal()"/>
<p-dialog header="Are you sure" class="signUpModal" [modal]="true" [(visible)]="showDeleteAccountModal"
    [draggable]="false" [closable]="false">
    <div>
        <span class="p-text-secondary">You are about delete your Aahaar account permanently.</span>
    </div>
    <div class="d-flex justify-content-end gap-2 mt-4">
        <p-button label="Cancel" severity="secondary" (onClick)="showDeleteAccountModal = false"/>
        <p-button label="Delete" severity="danger" (onClick)="initDeleteUserAccount()"/>
    </div>
</p-dialog>