<div class="mainContainer vw-100" [style.height.px]="screenHeight">
    <div class="subContainer gap-2 d-flex flex-column gap-3">
        <div class="toolBar w-100 d-flex justify-content-between">
            <div class="backBtn" (click)="navigateToHome()">
                <i class="pi pi-chevron-left"></i>
            </div>
            <div class="searchBar">
                <span class="iconField">
                    <p-iconField iconPosition="left">
                        <p-inputIcon styleClass="pi pi-search" />
                        <input type="text" pInputText placeholder="Search" />
                    </p-iconField>
                </span>
                <span class="micBtn">
                    <i class="pi pi-microphone"></i>
                </span>
            </div>
            <div class="actionIcon">
                <i class="pi pi-heart"></i>
            </div>
        </div>
        <div class="cardSection d-flex justify-content-between align-items-center">
            <div class="restaurantInfo">
                <div class="titleSection">
                    <span class="titleName">Fruitify</span>
                    <span class="iconSection">
                        <i class="pi pi-exclamation-circle"></i>
                    </span>
                </div>
                <div class="timeInfo">
                    <i class="pi pi-stopwatch"></i> <span class="infoField">20-25 mins <span class="dummyCircle"></span>
                        1.5 km <span class="dummyCircle"></span> Sanivarapeta</span>
                </div>
            </div>
            <div class="chipSection d-flex flex-column align-items-center justify-content-center p-2">
                <div class="rateInfo d-flex justify-content-center">
                    <span class="iconStar">
                        <i class="pi pi-star-fill"></i>
                    </span>
                    <span class="metaInfo">
                        4.0
                    </span>
                </div>
                <div class="rateSection d-flex justify-content-center">
                    200 Ratings
                </div>
            </div>
        </div>
        <div class="discountCard d-flex justify-content-between">
            <div class="offerInfo d-flex align-items-center gap-3">
                <div class="iconField">
                    <i class="pi pi-tag"></i>
                </div>
                <div class="offerData">
                    <span class="mainData">
                        50% off Upto ₹100
                    </span>
                    <br>
                    <span class="subData">
                        Use welcome66 | Above ₹150
                    </span>
                </div>
            </div>
            <div class="statusIcon">
                <span class="limitDisplay">
                    1/3
                </span>
                <div class="dotIcon">
                    <span class="dotField"></span>
                    <span class="dotSection"></span>
                    <span class="dotCircle"></span>
                </div>
            </div>
        </div>
        <div class="btnSection d-flex gap-2">
            <div class="vegBtn">
                <label class="toggle" for="myToggle">
                    <input class="toggleInput" type="checkbox" id="myToggle">
                    <div class="toggleFill"></div>
                </label>
            </div>
            <div class="nonVegBtn">
                <label class="toggleBtn" for="myToggleBtn">
                    <input class="toggleBtnInput" type="checkbox" id="myToggleBtn">
                    <div class="toggleBtnFill"></div>
                </label>
            </div>
            <div class="statusPill">
                <i class="pi pi-star-fill"></i>
                <span class="bestSellers">Bestsellers</span>
            </div>
            <div class="filterPill">
                <i class="pi pi-sliders-v"></i>
                <span class="filetrPill">Filter</span>
            </div>
        </div>
        <div class="recommendField">
            <p-accordion [multiple]="false" styleClass="w-100" [activeIndex]="0">
                <p-accordionTab>
                    <ng-template pTemplate="icon">
                        <div style="display: none;"></div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <div class="accordionHeader d-flex align-items-center">
                            <span class="title">Recommended</span>
                            <span class="chevron">
                                <i class="pi pi-chevron-down"></i>
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="accordionBody vw-70 d-flex justify-content-between">
                            <div class="leftPanel justify-content-start d-flex gap-1">
                                <div class="highlightDiv d-flex gap-1">
                                    <span class="imageSection">
                                        <img src="../../../assets/icons/vegFood.png"
                                            alt="../../../assets/iocns/vegFood.png" height="34px" width="34px">
                                    </span>
                                    <div class="viewPill gap-0.5">
                                        <span class="pi pi-star-fill"></span>
                                        <span class="bestSellers">Bestsellers</span>
                                    </div>
                                    <span class="favIcon">
                                        <i class="pi pi-heart"></i>
                                    </span>
                                </div>
                                <div class="outletInfo d-flex ">
                                    <div class="outletMeta d-flex gap-2">
                                        <div class="outletName">
                                            Fruit Custard
                                        </div>
                                        <div class="outletRate d-flex">
                                            <span class="pi pi-star-fill"></span>
                                            <span class="ratings">4.0</span>
                                        </div>
                                    </div>
                                    <span class="itemInfo">In Desserts</span>
                                </div>
                                <span class="rupeeSection">
                                    ₹200
                                </span>
                                <span class="descriptionField truncate">
                                    A delightful dessert with the combination
                                    of medley fresh fruits along with fresh cream.
                                </span>
                            </div>
                            <div class="rightPanel">
                                <span class="imagecard">
                                    <img src="../../../assets/illustrations/fruitCustard.jpg"
                                        alt="../../../assets/illustrations/fruitCustard.jpg" height="110px">
                                </span>
                                <span class="imageBtn">
                                    <p-button label="Add" class="addLabel"></p-button>
                                </span>
                            </div>
                        </div>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>
        </div>
    </div>
</div>