import { Component, HostListener } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ToastService } from '../../shared/toast.service';
import { UserService } from '../../services/user.service';
import firebase from 'firebase/compat/app';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {

  isOnline!: boolean;
  showCompleteRegModal: boolean = false;
  showSpinner: boolean = false;
  environment: string = '';
  center: google.maps.LatLngLiteral = { lat: 24, lng: 12 }; // Default center
  zoom = 4; 
  localityName: string | null = null;
  user: any;
  userData: any;
  userRegistrationForm: FormGroup;
  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  showNav: boolean = false;
  activeScreen: string | null = 'home';
  activeLeft: number = 12;
  activeTop: number = 7;
  searchValue: string | null = null;

  constructor(private toastService: ToastService, private userService: UserService, private fb: FormBuilder, private afAuth: AngularFireAuth, private router: Router) {
    this.getScreenSize();
    this.userRegistrationForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mobileNumber: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
    });
    this.showSpinner = true;
    firebase.auth().onAuthStateChanged((user: any) => {
      if (user) {
        this.user = user;
        this.userService.getCurrentUser(this.user.uid).subscribe((res: any) => {
          if (!res.data().hasOwnProperty('firstName')) {
            console.warn('complete sign up');
            this.showCompleteRegModal = true;
            this.showSpinner = false;
            this.showNav = false;
          } else {
            this.showNav = true;
            this.showSpinner = false;
            this.userData = res.data();
          }
        })
      } else {
        console.warn("User not logged in");
        // User not logged in or has just logged out.
      }
    });
  }

  ngAfterViewInit() {
    this.updateActiveBackgroundPosition();
  }

  ngOnInit() {
    this.environment = environment.environmentMode;
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      // Assuming you have latitude and longitude values available (e.g., from geolocation)
      // this.userService.getWeatherData(position.coords.latitude, position.coords.longitude)
      // .subscribe((data: any) => {
      //   const isRainy = data.current.condition.text.toLowerCase().includes('rain');
      //   const isSunny = data.current.condition.text.toLowerCase().includes('sunny');

      //   if (isRainy) {
      //     console.log("It's rainy!");
      //   } else if (isSunny) {
      //     console.log("It's sunny!");
      //   } else {
      //     console.log('Current weather:', data.current.condition.text);
      //   }
      // }, (error) => {
      //   console.error('Error fetching weather data:', error);
      // });
      // this.getLocalityName();
    }, () => {
      console.error('Error getting location');
      // this.getLocalityName(); // Fallback to default coordinates
    });
  }

  getLocalityName() {
    if(localStorage.getItem("Locality") == null || localStorage.getItem("Locality") == '') {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: this.center }, (results, status) => {
        console.warn(results);
        if (status === 'OK' && results && results[0]) {
          const addressComponents = results[0].address_components;
          const localityComponent = addressComponents.find(component =>
            component.types.includes('locality') || component.types.includes('sublocality_level_1') 
          );
          this.localityName = localityComponent ? localityComponent.long_name : null;
          localStorage.setItem("Locality", this.localityName ? this.localityName : '');
        } else {
          console.error('Geocoder failed due to: ' + status);
        }
      });
    } else {
      this.localityName = localStorage.getItem("Locality");
    }
  }

  enableCompleteRegistrationForm() {
    this.showCompleteRegModal = true;
  }

  updateUserDetails() {
    this.showSpinner = true;
    var obj = {
      firstName: this.userRegistrationForm?.value.firstName,
      lastName: this.userRegistrationForm?.value.lastName,
      mobileNumber: this.userRegistrationForm?.value.mobileNumber
    }
    this.userService.completeUserRegistration(this.user.uid, obj).then((res) => {
      this.toastService.showSuccess("Registration completed successfully");
      this.showCompleteRegModal = false;
      this.showSpinner = false;
      this.showNav = true;
    }, err => {
      this.showSpinner = false;
      this.toastService.showError(`Error completing registration. ${err}`)
    })
  }

  updateActiveScreen(screen: string, event: MouseEvent) {
    this.activeScreen = screen;
    this.updateActiveBackgroundPosition(event);
  }

  updateActiveBackgroundPosition(event?: MouseEvent) {
    if (event) {
      const target: any = event.currentTarget as HTMLElement;
      const rect = target.getBoundingClientRect();
      this.activeLeft = rect.left - target.offsetParent.getBoundingClientRect().left + (target.offsetWidth / 2) - 24;
      this.activeTop = rect.top - target.offsetParent.getBoundingClientRect().top + (target.offsetHeight / 2) - 24;
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }
}
