import { Component, EventEmitter, Output } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ToastService } from '../../../shared/toast.service';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import firebase from 'firebase/compat/app';

@Component({
  selector: 'app-privacy-security',
  templateUrl: './privacy-security.component.html',
  styleUrl: './privacy-security.component.scss'
})
export class PrivacySecurityComponent {

  showDeleteAccountModal: boolean = false;
  user: any;
  @Output() showSpinner = new EventEmitter<boolean>(); 

  constructor(private afAuth: AngularFireAuth, private toastService: ToastService, private router: Router, private userService: UserService) {
    firebase.auth().onAuthStateChanged((user: any) => {
      if (user) {
        this.user = user;
      } else {
        console.warn("User not logged in");
        // User not logged in or has just logged out.
      }
    });
  }

  enableDeleteModal() {
    this.showDeleteAccountModal = true;
  }

  initDeleteUserAccount() {
    this.showSpinner.emit(true);
    if (this.user) {
      this.user.delete()
      .then(() => {
        console.log('User deleted successfully');
        this.userService.deleteCurrentUser(this.user.uid);
        this.showSpinner.emit(false);
        this.toastService.showSuccess('User deleted successfully');
        this.router.navigateByUrl('login')
      })
      .catch((error: any) => {
          this.showSpinner.emit(false);
          console.error('Error deleting user:', error);
          // Handle any deletion errors, e.g., display an error message to the user
        });
      } else {
        this.showSpinner.emit(false);
        console.warn('No user is currently logged in.');
      // Handle the case where there's no authenticated user
    }
  }

}
