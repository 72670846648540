<div class="mainContainer vw-100">
    <div class="navBar d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-baseline brandLogo" style="gap: 3px;" (click)="navigateToHome()">
            <span class="aahaarTitleOblong">AA</span>
            <span class="aahaarTitle">haar</span>
        </div>
        <div class="profileSection d-flex align-items-center">
            <span class="profileImg">
                <img src="assets/profile/user-circle.png" alt="">
            </span>
            <span class="userNameSpan">
                {{userData?.firstName}}
            </span>
            <span class="pi pi-chevron-down" (click)="op.toggle($event)"></span>
    
            <p-overlayPanel #op>
                <ng-template pTemplate style="padding: 0 !important;">
                    <div class="d-flex flex-column">
                        <span class="overlayPanelItem" (click)="navigateToUserSettings()">
                            Settings
                        </span>
                        <span class="overlayPanelItem" (click)="logOut()">
                            Log Out
                        </span>
                    </div>
                </ng-template>
            </p-overlayPanel>
        </div>
    </div>
</div>